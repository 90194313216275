.image-text-small {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 25px 140px 0 140px;
    align-items: center;

    @media(max-width: 992px) {
        margin: 25px 40px 0 40px;
    }

    @media(max-width: 768px) {
        grid-template-columns: 1fr;
        margin: 25px 20px 0 20px;

        &.flipped {
            display: flex;
            flex-direction: column-reverse;
        }
    }

    &__content {
        width: 100%;
        padding-right: 10px;

        @media(max-width: 768px) {
            padding-bottom: 40px;
        }

        &.content-flipped {
            padding-right: 0;
            padding-left: 130px;

            @media(max-width: 992px) {
                padding-left: 80px;
            }

            @media(max-width: 768px) {
                padding-left: 0;
                padding-right: 10px;
            }
        }

        &__title {
            color: var(--color-light);
            font-size: var(--font-size-desktop-h3);
            margin-bottom: 30px;
            display: block;
            text-transform: uppercase;

            @media(max-width: 768px) {
                font-size: var(--font-size-mobile-h1);
                color: var(--color-black);
            }
        }

        &__text {
            max-width: 70%;

            @media(max-width: 1200px) {
                max-width: 90%;
            }

            @media(max-width: 768px) {
                max-width: 100%;
            }
        }
    }

    &__image {
        width: 100%;
        padding-left: 130px;
        position: relative;
        max-width: 700px;
        height: 480px;

        @media(max-width: 768px) {
            padding-top: 180px;

            &::before {
                content: '';
                display: block;
                width: 1px;
                height: 160px;
                background-color: var(--color-black);
                position: absolute;
                left: 50%;
                top: 0;
            }
        }

        @media(max-width: 400px) {
            padding-left: 20px;
        }

        &.image-flipped {
            padding-left: 0;
            padding-right: 130px;

            @media(max-width: 992px) {
                padding-right: 80px;
            }

            @media(max-width: 400px) {
                padding-right: 20px;
            }
        }

        img {
            width: 100%;
            object-fit: cover;
            height: 100%;
        }
    }
}