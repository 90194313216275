.footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-black);
    min-height: 260px;
    padding: 30px 20px;

    @media(max-width: 768px) {
        display: none;
    }

    &__wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 20px;
        max-width: 55%;

        @media(max-width: 1100px) {
            max-width: 80%;
        }

        @media(max-width: 992px) {
            max-width: 100%;
        }

        &__row {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &:not(:last-child) {
                padding-right: 40px;
                border-right: 1px solid var(--color-white);
            }

            &__group {
                display: flex;
                flex-direction: column;

                &:last-child {
                    margin-top: 40px;

                    @media(max-width: 768px) {
                        margin-top: 0px;
                    }
                }

                a, p, span {
                    color: var(--color-white);
                    font-size: var(--font-size-desktop-captions);
                    margin: 0;
                }

                &__title {
                    text-transform: uppercase;
                }
            }
        }
    }

    &__logo {
        @media(max-width: 992px) {
            display: none;
        }

        &__box {
            height: 150px;
            width: 150px;
            border: 3px solid var(--color-white);
        }
    }
}

.mobile-footer {
    display: none;
    background-color: var(--color-black);
    min-height: 300px;
    padding: 30px 20px;

    @media(max-width: 768px) {
        display: flex;
    }

    @media(max-width: 500px) {
        min-height: 400px;
    }

    @media(max-width: 400px) {
        flex-direction: column;
    }

    &__column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        @media(max-width: 400px) {
            .footer__wrapper__row__group {
                &:not(:last-child) {
                    margin-bottom: 20px;
                }
            }
        }
        
        &:first-child {
            border-right: 1px solid var(--color-white);
            margin-right: 15px;
            padding-right: 10px;
            max-width: 45%;

            @media(max-width: 400px) {
                max-width: 100%;
                border-right: 0;
                margin-bottom: 20px;
                margin-right: 0;
                padding-right: 0;
            }
        }
    }
}