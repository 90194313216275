.project-testimonial-mobile {
    display: none;
    flex-direction: column;
    margin: 0 20px;

    @media(max-width: 992px) {
        display: flex;
    }

    &__title {
        font-size: var(--font-size-mobile-h1);
        margin-bottom: 30px;
    }

    &__text {
        margin-bottom: 40px;
    }
}