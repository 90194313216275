.privacy-policy {
    margin: 200px auto 200px auto;
    max-width: 50%;

    @media(max-width: 768px) {
        max-width: 80%;
        margin: 100px auto 100px auto;
    }

    h1 {
        font-size: var(--font-size-desktop-h1);
        margin-bottom: 20px;
        text-transform: uppercase;
    }

    p {
        margin-bottom: 20px;

        a {
            color: var(--color-black);
            text-decoration: underline;
        }
    }
}