:root {
    --color-black: #000000;
    --color-white: #ffffff;
    --color-light: #6c6b6b;
    --color-muted: #c3bbbb;

    --font-size-desktop-h1: 30px;
    --font-size-desktop-h2: 28px;
    --font-size-desktop-h3: 16px;
    --font-size-desktop-body: 20px;
    --font-size-desktop-small-caps: 22px;
    --font-size-desktop-captions: 15px;

    --font-size-mobile-h1: 18px;
    --font-size-mobile-h2: 15px;
    --font-size-mobile-body: 14px;
    --font-size-mobile-small: 12px;
    --font-size-mobile-feature-stats: 20px;
    --font-size-mobile-portfolio-stats: 16px;
}