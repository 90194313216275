nav {
    max-width: 100%;
    padding-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    background-color: var(--color-white);
    left: 0;
    width: 100%;
    z-index: 100;

    @media(max-width: 768px) {
        padding-right: 20px;
    }

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        border-bottom: 1px solid var(--color-black);
        width: 100%;

        @media(max-width: 768px) {
            width: calc(100% - 40px);
        }
    }

    .nav__logo {
        text-transform: uppercase;
        font-size: var(--font-size-desktop-h3);
        text-decoration: none;
        color: var(--color-black);

        @media(max-width: 768px) {
            font-size: var(--font-size-mobile-body);
        }
    }

    .nav__links {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: inline-flex;
        align-items: center;

        li {
            text-transform: uppercase;

            &:not(:last-child) {
                margin-right: 40px;
            }

            a {
                color: var(--color-black);
                text-decoration: none;
                font-size: var(--font-size-desktop-h3);
            }
        }

        @media(max-width: 768px) {
            display: none;
        }
    }

    .nav__button {
        padding: 20px;
        background-color: var(--color-black);
        text-transform: uppercase;
        border: 0;
        -webkit-appearance: none;
        color: var(--color-white);
        cursor: pointer;
        font-size: var(--font-size-desktop-h3);

        @media(max-width: 768px) {
            display: none;
        }
    }

    .nav__mobile__button {
        display: none;
        margin: 15px 0;
        background: none;
        border: 0;
        cursor: pointer;
        -webkit-appearance: none;
        outline: none;

        @media(max-width: 768px) {
            display: block;
        }
    }
}

.navigation__mobile {
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-black);
    z-index: 9999;
    padding: 15px 20px;
    transition: all 0.3s ease-in-out;

    &.active {
        opacity: 1;
        pointer-events: all;
    }

    &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        border-bottom: 1px solid var(--color-white);
        padding-bottom: 15px;

        .nav__logo {
            color: var(--color-white);
            text-decoration: none;
            text-transform: uppercase;
        }

        .nav__mobile__button {
            color: var(--color-white);
            background: none;
            border: 0;
            cursor: pointer;
            -webkit-appearance: none;
            outline: none;

            svg line { 
                stroke: var(--color-white);
            }
        }
    }

    &__links {
        list-style-type: none;
        padding: 0;
        margin: 0;
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        li {
            text-transform: uppercase;
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }

            a {
                color: var(--color-white);
                text-decoration: none;
                font-size: var(--font-size-desktop-h3);
            }
        }
    }

    &__contact {
        margin-top: 30%;

        &__title {
            text-transform: uppercase;
            color: var(--color-white);
            font-size: var(--font-size-desktop-h3);
            margin-bottom: 30px;
            display: block;
        }

        &__blocks {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 60px;
            grid-row-gap: 40px;

            @media(max-width: 450px) {
                grid-template-columns: 1fr;
            }
    
            &__block {
                display: flex;
                flex-direction: column;
    
                &__title {
                    text-transform: uppercase;
                }
    
                &__title, &__text {
                    color: var(--color-white);
                    font-size: var(--font-size-mobile-small);
                    text-decoration: none;
                }
            }
        }
    }
}