.project-summary {
    display: flex;
    flex-direction: column;
    margin: 35px 20px;

    &__title {
        font-size: var(--font-size-desktop-h3);
        color: var(--color-light);
        text-transform: uppercase;
        margin-bottom: 30px;

        @media(max-width: 992px) {
            font-size: var(--font-size-mobile-h1);
            color: var(--color-black);
            margin-bottom: 20px;
        }
    }

    &__content {
        max-width: 50%;
        font-size: var(--font-size-desktop-h1);

        @media(max-width: 992px) {
            max-width: 100%;
            font-size: var(--font-size-mobile-body);
        }
    }
}