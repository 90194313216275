.icon-plus {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: var(--color-black);
        transform: translateY(-50%);
        zoom: 0.5;
    }

    &::after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: var(--color-black);
        transform: rotate(90deg);
    }
}

.icon-minus {
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: var(--color-black);
        transform: translate(-50%);
        zoom: 0.5;
    }
}

.icon-close {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: var(--color-black);
        transform: translate(-50%) rotate(45deg);
        zoom: 0.5;
        position: absolute;
        top: 50%;
        left: 50%;
    }

    &::after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: var(--color-black);
        transform: translate(-50%) rotate(135deg);
        zoom: 0.5;
        position: absolute;
        top: 50%;
        left: 50%;
    }
}