.project-header {
    display: grid;
    grid-template-columns: 75% calc(25% - 20px);
    grid-column-gap: 20px;
    margin: 75px 20px 0 20px;
    padding-bottom: 35px;
    border-bottom: 1px solid var(--color-black);

    @media(max-width: 1750px) {
        grid-template-columns: 60% calc(40% - 20px);
    }

    @media(max-width: 992px) {
        display: flex;
        flex-direction: column;
        padding-bottom: 10px;
    }

    &__owl__wrapper {
        max-width: 100%;
        display: flex;
        flex-direction: column;

        .owl-carousel .owl-item img {
            max-height: 80vh;
            object-fit: cover;
            object-position: top;
            height: 100%;

            @media(max-width: 992px) {
                max-height: auto;
            }
        }
    }

    &__navigation {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 40px;

        &__title {
            font-size: var(--font-size-desktop-h2);
            text-transform: uppercase;

            @media(max-width: 992px) {
                font-size: var(--font-size-mobile-h2);
            }
        }

        &__buttons {
            display: flex;
            align-items: center;

            &__button {
                background: none;
                border: none;
                cursor: pointer;
                outline: none;
                -webkit-appearance: none;

                &:first-child {
                    margin-right: 20px;
                }
            }
        }
    }

    &__content {
        @media(max-width: 992px) {
            display: flex;
            flex-direction: row;
            width: 100%;
            border-top: 1px solid var(--color-black);
            margin-top: 15px;
            padding: 10px 0;
        }

        &__title {
            font-size: var(--font-size-desktop-h3);
            color: var(--color-light);
            text-transform: uppercase;
            
            @media(max-width: 992px) {
                margin-right: 20px;
                padding-right: 20px;
                border-right: 1px solid var(--color-black);
                width: 20%;
                font-size: var(--font-size-mobile-h1);
                color: var(--color-black);
            }

            @media(max-width: 550px) {
                width: 30%;
            }
        }

        &__keyfacts {
            margin-top: 20px;

            @media(max-width: 992px) {
                margin-top: 0;
                width: 100%;
            }

            &__item {
                padding: 15px 120px;
                border-bottom: 1px solid var(--color-black);
                display: flex;
                flex-direction: column;

                &.no-border {
                    border-bottom: 0;
                }

                @media(max-width: 1200px) {
                    padding: 15px 50px;
                }

                @media(max-width: 992px) {
                    padding: 15px 0;
                }
                
                &:first-child {
                    border-top: 1px solid var(--color-black);

                    @media(max-width: 992px) {
                        border-top: none;
                        padding-top: 0;
                    }
                }

                &:last-child {
                    @media(max-width: 992px) {
                        border-bottom: 0;
                        padding-bottom: 0;
                    }
                }

                &__title {
                    font-size: var(--font-size-desktop-h3);
                    color: var(--color-light);
                    text-transform: uppercase;

                    @media(max-width: 992px) {
                        font-size: var(--font-size-mobile-small);
                    }

                    &.margin {
                        margin-top: 25px;
                        display: block;
                    }
                }

                &__value {
                    font-size: var(--font-size-desktop-h1);
                    width: 100%;

                    @media(max-width: 992px) {
                        font-size: var(--font-size-mobile-portfolio-stats);
                    }
                }
            }
        }

        &__testimonial {
            display: flex;
            flex-direction: column;
            margin-top: 30px;

            @media(max-width: 992px) {
                display: none;
            }

            &__title {
                font-size: var(--font-size-desktop-h3);
                color: var(--color-light);
                text-transform: uppercase;
                display: block;
                margin-bottom: 30px;
            }

            &__text {
                margin-bottom: 30px;
                display: block;
            }
        }
    }
}