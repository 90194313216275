.portfolio-header {
    margin: 75px 20px 20px 20px;
    padding: 0 100px 10px 100px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 120px;
    align-items: center;
    position: relative;
    border-bottom: 1px solid var(--color-black);

    @media(max-width: 992px) {
        padding: 0 50px 10px 50px;
    }

    @media(max-width: 768px) {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        padding: 0;
        padding-bottom: 10px;
        margin: 75px 20px 0 20px;
    }

    &__line {
        position: absolute;
        top: 0;
        height: calc(100% - 10px);
        width: 1px;
        background-color: var(--color-black);
        left: 50%;

        @media(max-width: 768px) {
            display: none;
        }
    }

    &__filters {
        display: flex;
        flex-direction: column;
        position: relative;

        &__labels, &__sublabels {
            ul {
                list-style-type: none;
                padding: 0;
                margin: 0;
                display: inline-flex;
                flex-wrap: wrap;

                li {
                    color: var(--color-muted);
                    text-transform: uppercase;
                    font-size: var(--font-size-desktop-h3);
                    cursor: pointer;
                    transition: color .2s ease-in-out;
                    user-select: none;

                    &.active {
                        color: var(--color-black);
                    }

                    &:not(:last-child) {
                        margin-right: 50px;
                    }
                }
            }
        }

        &__labels {
            margin-bottom: 10px;
        }

        &__sublabels {
            opacity: 0;
            transition: opacity .2s ease-in-out;
            pointer-events: none;
            position: absolute;
            top: 25px;

            @media(max-width: 1480px) {
                top: 50px;
            }

            @media(max-width: 900px) {
                top: 75px;
            }

            @media(max-width: 768px) {
                top: unset;
                position: relative;
                height: 0;
            }

            &.active {
                opacity: 1;
                pointer-events: all;
                height: auto;
            }
            
            ul li {
                &:not(:last-child) {
                    margin-right: 15px;
                }
            }
        }

        h2 {
            text-transform: uppercase;
            margin-bottom: 15px;
        }

        p {
            max-width: 80%;
            font-size: var(--font-size-desktop-captions);
        }
    }

    &__content {
        padding: 30px 0;

        &__title {
            font-size: var(--font-size-desktop-h2);
            margin-bottom: 30px;
            text-transform: uppercase;

            @media(max-width: 768px) {
                margin-bottom: 0;
            }
        }

        &__text {
            font-size: var(--font-size-desktop-h1);

            @media(max-width: 768px) {
                display: none;
            }
        }
    }
}