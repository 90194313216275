.testimonials {
    position: relative;
    margin-top: 20px;
    padding-top: 210px;
    margin-bottom: 135px;

    @media(max-width: 768px) {
        padding-top: 100px;
        margin-bottom: 0;
    }

    &:before {
        content: '';
        height: 210px;
        width: 1px;
        background-color: var(--color-black);
        display: block;
        position: absolute;
        left: 50%;
        top: 0;

        @media(max-width: 768px) {
            height: 100px;
        }
    }

    &__title {
        text-align: center;
        text-transform: uppercase;
        margin-top: 20px;
        margin-bottom: 20px;

        @media(max-width: 768px) {
            text-align: start;
            margin-left: 20px;
            font-size: var(--font-size-mobile-h1);
        }
    }

    &__wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 256px;
        position: relative;

        @media(max-width: 768px) {
            grid-template-columns: 1fr;
            grid-row-gap: 40px;
        }

        &__line {
            position: absolute;
            left: 50%;
            height: 100%;
            width: 1px;
            background-color: var(--color-black);

            @media(max-width: 768px) {
                display: none;
            }
        }

        &__item {
            padding: 50px 0 50px 140px;

            @media(max-width: 992px) {
                padding: 50px 0 50px 100px;
            }

            @media(max-width: 850px) {
                padding: 50px 0 50px 60px;
            }

            @media(max-width: 768px) {
                padding: 0px 140px 50px 20px;
            }

            @media(max-width: 350px) {
                padding: 0px 20px 20px 20px;
            }

            &:last-child {
                padding: 50px 140px 50px 0;

                @media(max-width: 992px) {
                    padding: 50px 100px 50px 0;
                }

                @media(max-width: 850px) {
                    padding: 50px 60px 50px 0;
                }

                @media(max-width: 768px) {
                    padding: 0px 20px 20px 140px;
                }

                @media(max-width: 350px) {
                    padding: 0px 20px 20px 20px;
                }
            }

            &__text {
                margin-bottom: 40px;
            }
        }
    }
}