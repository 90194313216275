.contact-overlay {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100%;
    width: 50%;
    background-color: var(--color-black);
    z-index: 9999;
    padding: 30px 25px;
    transition: all .6s ease-in-out;

    @media(max-width: 992px) {
        width: 100%;
    }

    &__open {
        right: 0;
        transition: all .6s cubic-bezier(.16,.64,.29,.81);
    }

    &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &__button {
            -webkit-appearance: none;
            background: none;
            border: 0;
            outline: none;
            cursor: pointer;
            height: 30px;
            width: 30px;

            .icon-close {
                width: 30px;

                &::before, &::after {
                    background-color: var(--color-white);
                }
            }
        }

        &__title {
            color: var(--color-white);
            text-transform: uppercase;
            font-size: var(--font-size-desktop-h3);
        }
    }

    &__content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 60px;
        grid-row-gap: 90px;
        margin: 120px;

        @media(max-width: 1500px) {
            margin: 120px 60px;
        }

        @media(max-width: 1250px) {
            margin: 120px 30px;
        }

        @media(max-width: 600px) {
            grid-template-columns: 1fr;
            margin: 120px 0;
        }

        &__block {
            display: flex;
            flex-direction: column;

            &__title {
                text-transform: uppercase;
            }

            &__title, &__text {
                color: var(--color-white);
                font-size: var(--font-size-desktop-captions);
            }
        }
    }
}