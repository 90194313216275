.alt-block {
    background-color: var(--color-black);
    padding: 100px 20px;

    @media(max-width: 768px) {
        background-color: var(--color-white);
        padding: 100px 20px 40px 20px;
    }

    &__header {
        margin-bottom: 100px;
        padding: 0 250px;

        @media(max-width: 992px) {
            padding: 0 100px;
        }

        @media(max-width: 768px) {
            padding: 0;
            margin-bottom: 80px;
        }

        &__title {
            text-transform: uppercase;
            color: var(--color-white);
            margin-bottom: 30px;

            @media(max-width: 768px) {
                color: var(--color-black);
                font-size: var(--font-size-mobile-h1);
            }
        }

        &__text {
            font-size: var(--font-size-desktop-h1);
            color: var(--color-white);
            max-width: 75%;

            @media(max-width: 1200px) {
                max-width: 85%;
            }

            @media(max-width: 992px) {
                max-width: 100%;
            }

            @media(max-width: 768px) {
                color: var(--color-black);
                font-size: var(--font-size-mobile-body);
            }
        }
    }

    &__stats {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 130px;
        position: relative;

        @media(max-width: 768px) {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin-bottom: 45px;
        }

        &__line {
            position: absolute;
            left: 50%;
            top: 0;
            height: 100%;
            width: 1px;
            background-color: var(--color-white);

            @media(max-width: 768px) {
                display: none;
            }
        }

        &__item {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 18px 0;

            @media(max-width: 768px) {
                width: 250px;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column-reverse;

                &:last-child {
                    border-top: 1px solid var(--color-black);
                }
            }

            &__title {
                font-size: var(--font-size-desktop-small-caps);
                color: var(--color-white);
                margin-bottom: 5px;
                display: block;
                text-transform: uppercase;

                @media(max-width: 768px) {
                    color: var(--color-black); 
                    font-size: var(--font-size-mobile-feature-stats);
                }
            }

            &__subtitle {
                font-size: var(--font-size-desktop-captions);
                color: var(--color-white);
                text-transform: uppercase;

                @media(max-width: 768px) {
                    color: var(--color-black);
                    font-size: var(--font-size-mobile-captions);
                }
            }
        }
    }

    &__image-text {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        @media(max-width: 992px) {
            grid-template-columns: 1fr;
        }

        @media(max-width: 768px) {
            border-bottom: 1px solid var(--color-black);
            padding-bottom: 40px;
        }

        &__image {
            width: 100%;

            @media(max-width: 992px) {
                display: none;
            }

            img {
                width: 100%;
            }
        }

        &__content {
            padding: 0 140px;

            @media(max-width: 1200px) {
                padding: 0 100px;
            }

            @media(max-width: 768px) {
                padding: 0;
            }

            &__title {
                font-size: var(--font-size-desktop-h2);
                color: var(--color-white);
                text-transform: uppercase;
                margin-bottom: 35px;

                @media(max-width: 768px) {
                    color: var(--color-black);
                    font-size: var(--font-size-mobile-h1);
                    margin-bottom: 30px;
                }
            }

            &__text {
                font-size: var(--font-size-desktop-h1);
                color: var(--color-white);

                @media(max-width: 768px) {
                    color: var(--color-black);
                    font-size: var(--font-size-mobile-body);
                }
            }
        }

        &__button {
            display: none;
            margin-top: 40px;
            width: 100%;
            justify-content: center;

            @media(max-width: 768px) {
                display: flex;
            }

            &__link {
                text-decoration: none;
                color: var(--color-light);
                font-size: var(--font-size-mobile-h2);
                text-transform: uppercase;
            }
        }
    }
}