.project-more {
    display: flex;
    flex-direction: column;
    margin: 40px 20px;

    @media(max-width: 992px) {
        margin: 60px 20px;
        padding-top: 25px;
        border-top: 1px solid var(--color-black);
    }

    &__title {
        font-size: var(--font-size-desktop-h3);
        color: var(--color-light);
        text-transform: uppercase;
        margin-bottom: 30px;
        text-align: center;

        @media(max-width: 992px) {
            font-size: var(--font-size-mobile-h2);
            text-align: start;
        }
    }

    &__row {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;

        @media(max-width: 992px) {
            grid-template-columns: repeat(2, 1fr);
        }

        a {
            text-decoration: none;
        }

        &__item {
            display: flex;
            flex-direction: column;

            @media(max-width: 992px) {
                &:last-child {
                    display: none;
                }
            }

            &__image {
                width: 100%;
                margin-bottom: 20px;

                img {
                    width: 100%;
                    height: 450px;
                    object-fit: cover;

                    @media(max-width: 1250px) {
                        height: 350px;
                    }
                }
            }

            &__content {
                display: flex;
                flex-direction: column;

                &__title {
                    font-size: var(--font-size-desktop-h3);
                    color: var(--color-muted);
                    text-transform: uppercase;
                }
    
                &__location {
                    font-size: var(--font-size-desktop-h3);
                    color: var(--color-light);
                    text-transform: uppercase;
                }
            }
        }
    }
}