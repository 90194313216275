.section__cta {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 90px 0;

    @media(max-width: 768px) {
        padding: 100px 0 0 0;
    }

    &::before, &::after {
        content: '';
        width: 1px;
        height: 90px;
        background-color: var(--color-black);
        position: absolute;
        left: 50%;

        @media(max-width: 768px) {
            height: 100px;
        }
    }

    &::before {
        top: 0;
    }

    &::after {
        bottom: 0;

        @media(max-width: 768px) {
            display: none;
        }
    }

    &__heading {
        text-transform: uppercase;
        margin-bottom: 5px;
        margin-top: 20px;

        @media(max-width: 768px) {
            font-size: var(--font-size-mobile-h1);
            margin-bottom: 20px;
        }
    }

    &__text {
        margin: 0 40px 20px 40px;
        text-align: center;
        max-width: 50%;

        @media(max-width: 992px) {
            max-width: 70%;
        }

        @media(max-width: 500px) {
            max-width: 100%;
        }

        a {
            color: var(--color-black);
        }
    }

    &__link {
        color: var(--color-light);
        text-decoration: none;
        text-transform: uppercase;
        font-size: var(--font-size-desktop-h3);
        margin-bottom: 20px;

        @media(max-width: 768px) {
            font-size: var(--font-size-mobile-h2);
        }
    }
}