.image-block {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;

    img {
        height: 530px;

        @media(max-width: 992px) {
            width: 100%;
            object-fit: cover;
        }
    }
}