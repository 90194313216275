.about-block__mobile {
    display: none;
    margin: 20px;
    border-top: 1px solid var(--color-black);
    padding-top: 30px;
    padding-bottom: 40px;

    &__content__title {
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    &__content__text {
        margin-bottom: 40px;
    }

    &__content__link {
        text-transform: uppercase;
        color: var(--color-light);
        text-decoration: none;
        font-size: var(--font-size-mobile-h2);
    }

    @media(max-width: 768px) {
        display: block;
    }
}

.about-block {
    margin: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 40px;
    position: relative;

    @media(max-width: 768px) {
        border-top: 1px solid var(--color-black);
    }

    &__line {
        position: absolute;
        left: 50%;
        width: 1px;
        height: calc(100% - 20px);
        background-color: var(--color-black);
        top: 20px;

        @media(max-width: 768px) {
            display: none;
        }
    }

    &__content__mobile {
        display: none;

        @media(max-width: 768px) {
            display: block;
        }
    }

    &__content {
        border-top: 1px solid var(--color-black);

        @media(max-width: 768px) {
            display: none;
        }

        &__title {
            text-transform: uppercase;
            margin-bottom: 70px;
            margin-top: 20px;
        }

        &__wrapper {
            padding-left: 120px;
            padding-right: 50px;

            @media(max-width: 992px) {
                padding-left: 80px;
            }

            &__text {
                font-size: var(--font-size-desktop-h1);
                margin-bottom: 30px;
            }

            &__link {
                text-transform: uppercase;
                color: var(--color-light);
                text-decoration: none;
                font-size: var(--font-size-desktop-h3);
            }
        }
    }

    &__stats {
        display: flex;
        flex-direction: column;
        border-top: 1px solid var(--color-black);

        @media(max-width: 768px) {
            border-top: 0;
            border-left: 1px solid var(--color-black);
            padding-left: 10px;
            margin-top: 20px;
        }

        &__row {
            padding: 20px 120px;
            display: flex;
            flex-direction: column;

            @media(max-width: 992px) {
                padding: 20px 0px;
            }

            &:not(:last-child) {
                border-bottom: 1px solid var(--color-black);
            }

            &__title {
                font-size: 16px;
                color: var(--color-light);
                text-transform: uppercase;
            }

            &__text {
                font-size: var(--font-size-desktop-h1);
            }
        }
    }
}