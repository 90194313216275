.image-text {
    margin: 100px 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 40px;

    @media(max-width: 992px) {
        grid-template-columns: 1fr 50%;
    }

    @media(max-width: 768px) {
        grid-template-columns: 1fr;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media(max-width: 768px) {
            margin-bottom: 20px;
        }

        &__title {
            font-size: var(--font-size-desktop-h2);
            text-transform: uppercase;
            margin-bottom: 30px;

            @media(max-width: 768px) {
                font-size: var(--font-size-mobile-h1);
            }
        }

        &__text {
            font-size: var(--font-size-desktop-h1);
            max-width: 70%;

            @media(max-width: 1200px) {
                max-width: 90%;
            }

            @media(max-width: 768px) {
                font-size: var(--font-size-mobile-body);
                max-width: 100%;
            }
        }
    }

    &__image {
        width: 100%;
        padding-right: 100px;
        display: flex;
        justify-content: flex-end;

        @media(max-width: 992px) {
            padding-right: 0;
        }

        img {
            height: 480px;
            width: 480px;
            object-fit: cover;

            @media(max-width: 992px) {
                height: 100%;
                width: 100%;
            }
        }
    }
}