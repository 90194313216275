.portfolio-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 20px;
    margin: 0 20px 100px 20px;

    @media(max-width: 992px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media(max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
    }

    a {
        text-decoration: none;
    
        @media(max-width: 768px) {
            &:last-child {
                .portfolio-grid__item:after {
                    display: none;
                }
            }
        }
    }

    &__item {
        position: relative;
        padding-top: 50px;
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;

        &.last-item {
            &::after {
                display: none;
            }
        }

        &:nth-child(-n+6) {
            padding-top: 30px;
        }

        &:nth-child(3n) {
            .portfolio-grid__item__image {
                padding-right: 0;
            }
        }

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            border-top: 1px solid var(--color-black);
            bottom: 0;
        }

        &__image {
            margin-bottom: 20px;
            padding-right: 15px;

            @media(max-width: 768px) {
                padding-right: 0;
            }

            img {
                width: 100%;
                height: 375px;
                object-fit: cover;

                @media(max-width: 768px) {
                    height: 450px;
                }
            }
        }

        span {
            font-size: var(--font-size-desktop-captions);
            color: var(--color-light);
        }
    }
}