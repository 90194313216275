.section__image-text-grid {
    margin: 90px 20px 0 20px;
    display: flex;
    flex-direction: column;

    &__row {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        gap: 20px;
        width: 100%;

        &__line {
            background-color: var(--color-black);
            height: 100%;
            width: 1px;
            position: absolute;
            left: 50%;
        }
    
        &__text {
            max-width: calc(50% - 11px);

            h2 {
                max-width: 70%;

                @media(max-width: 768px) {
                    font-size: var(--font-size-mobile-body);
                    max-width: 100%;
                }
            }
        }
    
        &__image {
            max-width: calc(50% - 11px);
    
            img {
                width: 100%;

                @media(max-width: 768px) {
                    height: 300px;
                    object-fit: cover;
                }
            }
    
            &__caption {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                margin-top: 15px;
                text-transform: uppercase;
    
                &.align__right {
                    align-items: flex-start;
                }
    
                span {
                    font-size: var(--font-size-desktop-h3);
                    color: var(--color-light);

                    @media(max-width: 768px) {
                        font-size: var(--font-size-mobile-small);
                    }
                }
            }
        }
    }
}