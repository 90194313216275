@font-face {
    font-family: 'StyreneB';
    src:    url('../fonts/StyreneB-Regular-Web.woff2') format('woff2'),
            url('../fonts/StyreneB-Regular-Web.woff') format('woff');
}

* {
    box-sizing: border-box;
}

html, body {
    margin: 0;
    padding: 0;
    font-family: 'StyreneB';
    font-size: var(--font-size-desktop-body);

    @media(max-width: 768px) {
        font-size: var(--font-size-mobile-body);
    }
}

p {
    margin: 0;
}

h1, h2, h3, h4, h5 {
    margin: 0;
    font-weight: 400;
}

h1 {
    font-size: var(--font-size-desktop-h1);
}

h2 {
    font-size: var(--font-size-desktop-h2);
}

h3 {
    font-size: var(--font-size-desktop-h3);
}

// Page Specific
.page__about__desktop {
    display: none;

    @media(min-width: 768px) {
        display: block;
    }
}

.page__about__mobile {
    display: none;

    @media(max-width: 768px) {
        display: block;
    }
}

@import 'variables';

@import 'components/image-text-grid';
@import 'components/cta';
@import 'components/about-block';
@import 'components/image-text';
@import 'components/alt-block';
@import 'components/testimonials';
@import 'components/image-block';
@import 'components/image-text-small';
@import 'components/portfolio-header';
@import 'components/portfolio-grid';
@import 'components/team-grid';
@import 'components/team-footer';
@import 'components/navigation';
@import 'components/contact-overlay';
@import 'components/project-header';
@import 'components/project-summary';
@import 'components/project-more';
@import 'components/project-testimonial';
@import 'components/privacy-policy';
@import 'components/footer';
@import 'components/icons';