.team-footer {
    margin: 75px 20px 20px 20px;
    padding: 0 100px 10px 100px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 120px;
    align-items: center;
    position: relative;
    border-top: 1px solid var(--color-black);

    @media(max-width: 1200px) {
        padding: 0 64px 10px 64px;
    }

    @media(max-width: 992px) {
        display: none;
    }

    &__line {
        position: absolute;
        top: 25px;
        height: calc(100% - 50px);
        width: 1px;
        background-color: var(--color-black);
        left: 50%;
    }

    &__text {
        padding: 75px 128px;

        @media(max-width: 1310px) {
            padding: 75px 64px;
        }

        @media(max-width: 1200px) {
            padding: 75px 32px;
        }

        &__title {
            text-transform: uppercase;
            margin-bottom: 15px;
            font-size: var(--font-size-desktop-h2);
        }

        &__content {
            max-width: 80%;
            font-size: var(--font-size-desktop-captions);

            @media(max-width: 1200px) {
                max-width: 100%;
            }
        }
    }

    &__content {
        padding: 75px 128px;

        &__title {
            font-size: var(--font-size-desktop-h2);
            margin-bottom: 15px;
            text-transform: uppercase;
        }

        &__text {
            font-size: var(--font-size-desktop-captions);

            a {
                color: var(--color-black);
            }
        }
    }
}