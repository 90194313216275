.team-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 10px 100px 10px;
    
    &__item {
        display: flex;
        flex-direction: column;
        width: calc(100% / 6);
        padding-bottom: 40px;
        position: relative;
        padding: 20px 10px;
        cursor: pointer;

        @media(max-width: 992px) {
            width: calc(100% / 4);
        }

        @media(max-width: 768px) {
            width: calc(100% - 20px);
            margin: 0 auto;
            padding: 20px 0;

            &:not(:last-child) {
                border-bottom: 1px solid var(--color-black);
            }
        }

        &__open {
            width: calc(100% / 2);
            display: flex;
            flex-direction: row;

            @media(max-width: 992px) {
                width: 100%;
            }

            .team-grid__item__content {
                display: none;
            }

            .team-grid__item__image {
                width: 48.4%;
                margin-right: 20px;

                @media(max-width: 992px) {
                    width: 31.4%;
                }
            }

            .team-grid__item__extended {
                display: block;
                width: 100%;

                @media(max-width: 768px) {
                    display: flex;
                }
            }
        }

        &__extended {
            display: none;

            @media(max-width: 768px) {
                flex-direction: column;
                
                &__wrapper {
                    display: grid;
                    grid-template-columns: .5fr 1fr;
                    grid-column-gap: 20px;
                }

                &__image {
                    display: block;
                }
            }

            @media(max-width: 400px) {
                &__wrapper {
                    grid-template-columns: 1fr;
                }
            }

            &__info__mobile {
                display: none;
                flex-direction: column;
                margin-bottom: 10px;
                position: relative;
                margin-bottom: 40px;

                span {
                    font-size: var(--font-size-desktop-captions);
                    color: var(--color-light);
                }

                .icon-minus {
                    width: 21px;
                    position: absolute;
                    right: 0;
                    top: 50%;
                }

                @media(max-width: 768px) {
                    display: flex;
                }
            }

            &__image {
                width: 100%;
                display: none;

                @media(max-width: 400px) {
                    display: none;
                }

                img {
                    width: 100%;
                    height: 400px;
                    object-fit: cover;
                    object-position: top;

                    @media(max-width: 1400px) {
                        height: 300px;
                    }
                }
            }

            &__content {
                display: flex;
                flex-direction: column;

                &__info {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 10px;
                    position: relative;

                    @media(max-width: 768px) {
                        display: none;
                    }

                    span {
                        font-size: var(--font-size-desktop-captions);
                        color: var(--color-light);
                    }

                    .icon-minus {
                        width: 21px;
                        position: absolute;
                        right: 0;
                        top: 50%;
                    }
                }

                &__bio {
                    font-size: var(--font-size-desktop-captions);
                    margin-bottom: 20px;
                }

                &__email, &__linkedin {
                    text-decoration: underline;
                    color: var(--color-black);
                    font-size: var(--font-size-desktop-captions);
                    width: fit-content;
                }

                &__email {
                    text-transform: lowercase;
                }
            }
        }

        &:first-child {
            &::after {
                width: calc(100% + 10px);
            }
        }

        &:nth-child(7n) {
            &::after {
                width: calc(100% + 10px);
            }
        }

        &.last-item {
            &::after {
                display: none;
            }
        }

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            border-top: 1px solid var(--color-black);
            bottom: 0;

            @media(max-width: 768px) {
                display: none;
            }
        }

        &__image {
            width: 100%;

            @media(max-width: 768px) {
                display: none;
            }

            img {
                width: 100%;
                height: 400px;
                object-fit: cover;
                object-position: top;

                @media(max-width: 1400px) {
                    height: 300px;
                }
            }
        }

        &__content {
            margin-top: 25px;
            display: flex;
            flex-direction: column;
            position: relative;

            @media(max-width: 768px) {
                margin-top: 0;
            }

            span {
                color: var(--color-light);
                font-size: var(--font-size-desktop-captions);

                &:first-child {
                    max-width: 85%;
                    margin-bottom: 5px;
                }
            }

            .icon-plus {
                position: absolute;
                top: 0;
                right: 0;
                height: 21px;
                width: 21px;
            }
        }
    }
}